import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../store/store';
import { LoaderSpinnerOverlay } from '../../components/Loader/LoaderSpinnerOverlay';

import { strings } from '../../language/strings';

import './LoginPage.css'
import { LoginWidget } from '../../components/LoginWidget/LoginWidget';
import { RegisterWidget } from '../../components/RegisterWidget/RegisterWidget';
import { RecoverWidget } from '../../components/RecoverPassword/RecoverWidget';

import logoApple from "../../images/badge-apple.svg";
import logoGoogle from "../../images/badge-google.png";
import { Box } from '@mui/material';


interface LoginPageProps {
  //ejemplo?: string;
}

export const LoginPage : React.FC<LoginPageProps> = props => {
  /*const {
    ejemplo,
  } = props;*/

  const navigate = useNavigate();

  const loader = useSelector((state : RootState) => state.loading.loading);
  const token = useSelector((state : RootState) => state.auth.token); 

  const [widget, setWidget] = useState<number>(0);  //0: Login, 1:Register, 2: Cambio de Password

  useEffect(() => {
    if (token) {
      navigate('/home')
    }
  }, [token, navigate]);
  
  return ( 
    <div className='contentLogin'>
      <LoaderSpinnerOverlay visible={loader} paddingBottom={80}/> 
      {
        widget === 0 ?
          <LoginWidget setWidget={setWidget} />
          :
          (
            widget === 1 ?
            <RegisterWidget setWidget={setWidget} />
            :
            <RecoverWidget setWidget={setWidget} />
          )          
      }
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: 500 },
        }}
        noValidate
        autoComplete="off"
        style={{textAlign: 'center', margin: 'auto', padding: 32, paddingBottom: 32, backgroundColor: '#FFFFFF40', borderRadius: 16}}
      > 
        
        <span>{strings.downloadApp}</span>
        <a className="buttonApp" href="https://apps.apple.com/us/app/beinclub/id1645601962"><img width="100" src={logoApple} alt="AppleStore"/></a>
        
        <span>{strings.or}</span>
        <a className="buttonApp" href="https://play.google.com/store/apps/details?id=com.beinclub.app"><img width="110" src={logoGoogle} alt="GooglePlay"/></a>

       
      </Box>     
    </div>
  );
}  
