export const APP_NAME = 'BeInClub';
export const APPLICATION_ID = 14;
//export const APPLICATION_TOPIC = '14f4a393-1e05-44e7-b9de-a67ef38eac0d';
export const APPLICATION_TOPIC = 'beinclub';

//URL SERVER
//export const ENV = 'prod'; // modificar a prod cuando publicamos version
export const ENV = 'dev'; // modificar a dev cuando estamos desarrollando

export const URL_SERVIDOR = 'https://appdata.vipcard.group';
//export const URL_BEINCLUB = 'https://beinclub.nusgordia.com';
//export const URL_BEINCLUB = 'https://club.nusgordia.com';
//export const URL_BEINCLUB = 'http://127.0.0.1:8000';     // maq Eduard
export const URL_BEINCLUB = 'https://club.beyondinner.com';

export const URL_GET_TOKEN_IOS_BEINCLUB = URL_BEINCLUB + '/getToken.php';
export const URL_VIPCARD_BEINCLUB = 'https://static.vipcard.group/beinclub/';
export const URL_REDSYS = URL_BEINCLUB + '/api/pago/redsys?monto=';
export const URL_REDSYS_CONCEPTO = '&concepto=';

export const OUR_PHONE = 'tel:+34722554010';
export const URL_COMUNIDADES = 'https://beyondinner.com/comunidades/';
export const URL_TESTIMONIOS = 'https://www.youtube.com/watch?v=EoexRJT5O60';
export const URL_NUESTRO_EQUIPO = 'https://beyondinner.com/contacto/';
export const URL_SHARE_APP = 'http://bit.ly/vipcard_app';
export const URL_ENTRA = 'https://www.youtube.com/watch?v=n-SAeAIYkVg';
export const URL_PERTENECE = 'https://www.youtube.com/watch?v=Fbo5obQyb0g';
export const URL_DISFRUTA = 'https://www.youtube.com/watch?v=8sI9gvxpM1U';

//VIPCARD CDN
//export const URL_STATIC_CATEGORIES = 'https://static.vipcard.group/categories/';
export const URL_STATIC_CATEGORIES = 'https://static.vipcard.group/beinclub/categorias/';
export const URL_STATIC_ZONE_CATEGORIES = 'https://static.vipcard.group/beinclub/zones/zones.json';

export const URL_SIGNUP = '/api/auth/register';
export const URL_LOGIN = 'api/auth/login';
export const URL_FIREBASE_TOKEN = '/api/auth/firebaseToken';
export const URL_LOGIN_WEB = '/login';
export const URL_LOGOUT = 'api/auth/logout';
export const URL_CHANGE_PASSWORD = '/api/auth/change-password';
export const URL_RESET_PASSWORD = '/api/account/reset_password/init';
export const URL_CHANGE_PASSWORD_PIN = '/api/account/reset_password/change';
export const URL_DELETE_ACCOUNT = '/api/auth/delete';
export const URL_USER_ACCOUNT = '/api/auth/me';
export const URL_TODOS_PAISES = '/api/countries';
export const URL_IDIOMAS = '/api/languages';
export const URL_ZONAS_CATEGORIAS = '/api/zones';
export const URL_CATEGORIAS_BEIN = '/api/categories';
export const URL_ASESORES = '/api/advisors';
export const URL_CATEGORIA_ATRIBUTOS = '/api/attributeCategories';
export const URL_VENDEDOR = '/api/sellers';
export const URL_MIS_RESERVAS = '/api/events/active';
export const URL_MIS_MOVIMIENTOS = '/api/user/getTransactions/';
export const URL_RESERVA_O_COMPRA_PRIVADA = '/api/user/transfer';
export const URL_HOME_GALLERY = URL_VIPCARD_BEINCLUB + 'home_gallery/';
export const URL_UPLOAD_PHOTO = '/api/auth/uploadAvatar';
export const URL_UPDATE_USER = '/api/auth/me';
export const URL_CONVERT_IC = '/api/master/ic/exchange/';    //{euros}

export const USER_TOKEN = 'token_beinclub';
export const WEB_TOKEN = 'web_token_beinclub';
export const CURRENT_USER = 'user_beinclub';
export const LAST_RENEW_TOKEN = 'ultima_renovacion_beinclub';
export const LAST_RECOVER_USER = 'ultima_recuperacion_user_beinclub';
